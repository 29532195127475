import React from 'react';
import './App.css';
import './assets/style/hush.css'
import { BrowserRouter, Routes, Route } from "react-router-dom";
// view
import Page404 from './views/Page404/Page404';
import Home from './views/Home/Home';
import Features from './views/Features/Features';
import Pricing from './views/Pricing/Pricing';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

export default class App extends React.Component {

  render() {
    return (
      <div id='App'>
        <BrowserRouter>
        <Header/>
            <Routes>
                <Route path="*" element={<Page404 />} />
                <Route path="/" element={<Home />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/features" element={<Features />} />
            </Routes>
          <Footer/>
        </BrowserRouter>
      </div>
    )
  }
}