import React from 'react'
import { NavLink } from 'react-router-dom';
import './Pricing.css'
import { Player } from '@lottiefiles/react-lottie-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

class Pricing extends React.Component {
    render() {
        return (
            <main id='Pricing'>
                <section id='atf'>
                    <div id='box'>
                        <h2>
                            Choisissez <span>l'abonnement</span> qui vous convient le mieux
                        </h2>
                        <p>
                            Optimisez votre gestion avec nos différentes options d'abonnement adaptées à vos besoins et à votre budget.
                        </p>
                    </div>
                    <Player
                        autoplay
                        loop
                        src="https://assets9.lottiefiles.com/private_files/lf30_zqj0zajp.json"
                        style={{ width: '500px' }}
                    >
                    </Player>
                </section>
                <section id="offers">
                    <div className='title'>
                        <h3>Une <span>solution</span> pour chaques besoins</h3>
                        <p>Du particulier au professionel, tous le monde s'y retrouve</p>
                    </div>
                    <div id="grid">
                        <div className="grid-elm">
                            <div className="price-title">
                                <p className='title'>Gratuit</p>
                                <h4>0€ <span>/ par mois </span></h4>

                                <a className="button first" href="http://app.resaloc-france.com/signup">Créez un compte gratuit</a>
                            </div>

                            <p className="feature-title">Locataires & logements</p>

                            <ul>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Création de locataires (2 max)</li>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Création de logements (1 max)</li>
                            </ul>

                            <hr />

                            <p className="feature-title">Contrats & documents</p>

                            <ul>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Création et envoie de contrats</li>
                            </ul>
                        </div>
                        <div className="grid-elm">
                            <div className="price-title">
                                <p className='title'>Particuliers</p>
                                <h4>25€ <span>/ par mois </span></h4>

                                <a className="button" href="http://app.resaloc-france.com/signup">Essai gratuit de 14 jours</a>
                            </div>

                            <p className="feature-title">Locataires & logements</p>

                            <ul>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Création de locataires (5 max)</li>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Création de logements (3 max)</li>
                            </ul>

                            <hr />

                            <p className="feature-title">Contrats & documents</p>

                            <ul>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Création et envoie de contrats</li>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Sauvegarde de contrats</li>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Importation de documents</li>
                            </ul>

                            <hr />

                            <p className="feature-title">Paiement</p>

                            <ul>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Paiement des loyers</li>
                            </ul>

                            <hr />

                            <p className="feature-title">Assistance</p>

                            <ul>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Conseiller personnel</li>
                            </ul>
                        </div>
                        <div className="grid-elm featured">
                            <div className="price-title">
                                <p className='title'>Professionels</p>
                                <h4>75€ <span>/ par mois </span></h4>

                                <a className="button" href="http://app.resaloc-france.com/signup">Essai gratuit de 14 jours</a>
                            </div>

                            <p className="feature-title">Locataires & logements</p>

                            <ul>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Création de locataires (2 max)</li>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Création de logements (1 max)</li>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Importation de locataires </li>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Importation de logements </li>
                            </ul>

                            <hr />

                            <p className="feature-title">Contrats & documents</p>

                            <ul>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Création et envoie de contrats</li>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Sauvegarde de contrats</li>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Importation de documents</li>
                            </ul>

                            <hr />

                            <p className="feature-title">Paiement</p>

                            <ul>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Paiement des loyers</li>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Gestion des retards</li>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Envoie de relance</li>
                            </ul>

                            <hr />

                            <p className="feature-title">Analyse</p>

                            <ul>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Analyse des locations</li>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Analyse des revenues</li>
                            </ul>

                            <hr />

                            <p className="feature-title">Assistance</p>

                            <ul>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Conseiller personnel</li>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Assisstance prioritaire</li>
                            </ul>
                        </div>
                        <div className="grid-elm">
                            <div className="price-title">
                                <p className='title'>Équipes</p>
                                <h4>135€ <span>/ par mois </span></h4>

                                <a className="button" href="http://app.resaloc-france.com/signup">Essai gratuit de 14 jours</a>
                            </div>

                            <p className="feature-title">Locataires & logements</p>

                            <ul>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Création de locataires (2 max)</li>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Création de logements (1 max)</li>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Importation de locataires </li>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Importation de logements </li>
                            </ul>

                            <hr />

                            <p className="feature-title">Contrats & documents</p>

                            <ul>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Création et envoie de contrats</li>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Sauvegarde de contrats</li>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Importation de documents</li>
                            </ul>

                            <hr />

                            <p className="feature-title">Paiement</p>

                            <ul>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Paiement des loyers</li>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Gestion des retards</li>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Envoie de relance</li>
                            </ul>

                            <hr />

                            <p className="feature-title">Analyse</p>

                            <ul>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Analyse des locations</li>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Analyse des revenues</li>
                            </ul>

                            <hr />

                            <p className="feature-title">Assistance</p>

                            <ul>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Conseiller personnel</li>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Assisstance prioritaire</li>
                            </ul>

                            <hr />

                            <p className="feature-title">Équipes</p>

                            <ul>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Comptes admins</li>
                                <li className='included'><FontAwesomeIcon className='icon' icon={faCheck} />Comptes utilisateurs</li>
                            </ul>
                        </div>
                    </div>
                </section>
            </main>
        )
    }
}

export default Pricing