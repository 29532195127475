import React from 'react'
import { NavLink } from 'react-router-dom';
import './Home.css'
import { Player } from '@lottiefiles/react-lottie-player';
import avatarOne from '../../assets/images/avatarOne.png'
import avatarTwo from '../../assets/images/avatarTwo.png'
import avatarThree from '../../assets/images/avatarThree.png'

class Home extends React.Component {
    render() {
        return (
            <main id='Home'>
                <section id='atf'>
                    <div id='box'>
                        <h2>
                            Gérez vos <span>locations</span> en <br /> un clin d'oeil !
                        </h2>
                        <p>
                            Optimiser votre temps et celui de vos locataires en gérant tous au meme endroit.
                        </p>
                        <div className="btnBox">
                            <a className="button" href="http://app.resaloc-france.com/signup">Commencez gratuitement</a>
                            <NavLink className="button" to="/contact">Nous contacter</NavLink>
                        </div>
                    </div>
                    <Player
                        autoplay
                        loop
                        src="https://assets1.lottiefiles.com/packages/lf20_49rdyysj.json"
                        style={{ width: '500px' }}
                    >
                    </Player>
                </section>
                <section id='featured-features'>
                    <div className='title'>
                        <h3>
                            <span>Boostez</span> votre production
                        </h3>
                        <p>Exploiter le plein potentiel de vos locations et débloquer de nouveaux horizons</p>
                    </div>

                    <div id="grid">
                        <div className="grid-elm">
                            <Player
                                autoplay
                                loop
                                src="https://cdn.lordicon.com/vxsnbfop.json"
                                style={{ width: '150px' }}>
                            </Player>
                            <h4>Gérez vos logements</h4>
                            <p>
                                Enregistrez vos logements et oublier le dossier spécifiques. Tous se trouve au même endroit pour une gestion optimiser et intuitive, sans difficultées.
                            </p>
                            <NavLink className="button" to="/features">En savoir plus</NavLink>
                        </div>
                        <div className="grid-elm">
                            <Player
                                autoplay
                                loop
                                src="https://cdn.lordicon.com/ckatldkn.json"
                                style={{ width: '150px' }}>
                            </Player>
                            <h4>Créez vos contrats</h4>
                            <p>
                                Créez vos contrats en moins de 2 minutes est envoyer les à vos locataires en un clics et faite gagner du temps a vous et a vos locataires.
                            </p>
                            <NavLink className="button" to="/features">En savoir plus</NavLink>
                        </div>
                        <div className="grid-elm">
                            <Player
                                autoplay
                                loop
                                src="https://cdn.lordicon.com/oegrrprk.json"
                                style={{ width: '150px' }}>
                            </Player>
                            <h4>Analysez vos locations</h4>
                            <p>
                                Analyser vos locations au fils des mois pour mettre au points vos période d'activité et toucher vos locataire et de nouveaux prospect.
                            </p>
                            <NavLink className="button" to="/features">En savoir plus</NavLink>
                        </div>
                    </div>
                </section>
                <section id="testimonials">
                    {/* <h3>Témoignages</h3> */}
                    <div id="grid">
                        <div className="grid-elm">
                            <p>
                                Résaloc ma aider a organiser mes appartements et mes locataires. J'ai pue tout louer sans probleme pour les fêtes et les vacances de Février.
                            </p>
                            <div className="infos">
                                <img src={avatarOne} alt="placeholder 2" />
                                <div className="infos-text">
                                    <h4>Henri Miloura</h4>
                                    <p>Particulier</p>
                                </div>
                            </div>
                        </div>
                        <div className="grid-elm">
                            <p>
                                J'ai pu organiser les traveaux de mes studio et leurs locations grace a Résaloc. Du coup j'ai pu les louer sans problemes.
                            </p>
                            <div className="infos">
                                <img src={avatarTwo} alt="placeholder 2" />
                                <div className="infos-text">
                                    <h4>Marie Atouprix</h4>
                                    <p>SCI padenom</p>
                                </div>
                            </div>
                        </div>
                        <div className="grid-elm">
                            <p>
                                Je n'aurai jamais crus que gerer des locations et des contrats serai aussi simple. Fini les papier qui vole et les oublie d'envoie.
                            </p>
                            <div className="infos">
                                <img src={avatarThree} alt="placeholder 2" />
                                <div className="infos-text">
                                    <h4>SCI padenom</h4>
                                    <p>Les studios d'or</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="everywere">
                    <div className="title">
                        <h3>
                            Vos locations vous suivent lors de vos <span>déplacements</span>
                        </h3>
                        <p>
                            Surveiller vos location lors de vos voyage depuis votre ordinateur ou votre tablette, et rester connecter en toutes circonstances.
                        </p>
                    </div>
                    <Player
                        autoplay
                        loop
                        src="https://assets7.lottiefiles.com/private_files/lf30_dy2xdidy.json"
                        style={{ width: '440px' }}
                    >
                    </Player>
                </section>
                <section id="offers">
                    <div className='title'>
                        <h3>Une <span>solution</span> pour chaques besoins</h3>
                        <p>Du particulier à la grande entreprise, tous le monde s'y retrouve</p>
                    </div>

                    <div id="grid">
                        <div className="grid-elm">
                            <Player
                                autoplay
                                loop
                                src="https://cdn.lordicon.com/zthozvfn.json"
                                style={{ width: '150px' }}>
                            </Player>
                            <h4>Particuliers</h4>
                            <p>
                                Particulier ou débutant, gérez votre location de manière simple et intuitive conçue pour tous les propriétaires.
                            </p>
                            <NavLink className="button" to="/pricing">En savoir plus</NavLink>
                        </div>
                        <div className="grid-elm">
                            <Player
                                autoplay
                                loop
                                src="https://cdn.lordicon.com/nrtdaiif.json"
                                style={{ width: '150px' }}>
                            </Player>
                            <h4>Professionnels</h4>
                            <p>
                                Freelance ou professionnel, prenez le contrôle sur vos locations et optimisez votre temps et vos revenus.
                            </p>
                            <NavLink className="button" to="/pricing">En savoir plus</NavLink>
                        </div>
                        <div className="grid-elm">
                            <Player
                                autoplay
                                loop
                                delay="2000"
                                src="https://cdn.lordicon.com/secxpvll.json"
                                style={{ width: '150px' }}>
                            </Player>
                            <h4>Équipes</h4>
                            <p>
                                Profitez d'outils de gestion avancés pour optimiser votre productivité et simplifier la gestion du travail en commun..
                            </p>
                            <NavLink className="button" to="/pricing">En savoir plus</NavLink>
                        </div>
                    </div>
                </section>
                <section id="support">
                    <div className="advantage-elm">
                        <Player
                            autoplay
                            loop
                            src="https://assets10.lottiefiles.com/packages/lf20_eYXADRNJPy.json"
                            style={{ width: '300px' }}
                        >
                        </Player>
                        <div className="title">
                            <h3>
                                Une <span>assistance</span> dédiée
                            </h3>
                            <p>
                                Notre équipe d'assistance est à votre écoute pour répondre à toutes vos questions et vous aider à utiliser au mieux notre application.
                            </p>
                        </div>
                    </div>
                    <div className="advantage-elm">
                        <Player
                            autoplay
                            loop
                            src="https://assets9.lottiefiles.com/private_files/lf30_k0wpj0cx.json"
                            style={{ width: '300px' }}
                        >
                        </Player>
                        <div className="title">
                            <h3>
                                Mises à jour et <span>améliorations</span> 
                            </h3>
                            <p>
                                Notre équipe d'assistance est à votre écoute pour répondre à toutes vos questions et vous aider à utiliser au mieux notre application.
                            </p>
                        </div>
                    </div>
                </section>
                <section id='CTA'>
                    <h3>Prêt à <span>booster</span> vos location et à gagner du temps ?</h3>
                    <a className='button' rel='noreferrer' href="http://app.resaloc-france.com/signup">Commencez gratuitement</a>
                </section>
            </main>
        )
    }
}

export default Home