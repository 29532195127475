import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player';
import './page404.css';
import { NavLink } from 'react-router-dom';

class Page404 extends React.Component {
    render() {
        return (
            <main id='page404'>
                <p>Oups... Il semblerait que cette page n'existe pas.</p>
                <Player
                    autoplay
                    loop
                    src="https://assets10.lottiefiles.com/packages/lf20_2lxegjl6.json"
                    style={{ width: '500px' }}
                >
                </Player>
                <NavLink className="button" to='/'>Retourner à l'accueil</NavLink>
            </main>
        )
    }
}

export default Page404