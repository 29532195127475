import React from 'react'
// import { NavLink } from 'react-router-dom'
import './Footer.css'

class Footer extends React.Component {
    render() {
        return (
            <footer>
                <p>&copy; Resaloc france - {new Date().getFullYear()} By <a href="https://meedev.fr">Meedev</a></p>
            </footer>
        )
    }
}

export default Footer