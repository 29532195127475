import React from 'react'
import { NavLink } from 'react-router-dom';
import './Features.css'
import { Player } from '@lottiefiles/react-lottie-player';

class Features extends React.Component {
    render() {
        return (
            <main id='Features'>
                <section id='atf'>
                    <div id='box'>
                        <h2>
                            Toutes les <span>fonctionnalités</span> dont vous avez besoin !
                        </h2>
                        <p>
                            Optimisez votre productivité et simplifiez votre gestion grâce à nos outils avancés et faciles à utiliser.
                        </p>
                        <div className="btnBox">
                            <a className="button" href="http://app.resaloc-france.com/signup">Commencez gratuitement</a>
                            <NavLink className="button" to="/contact">Nous contacter</NavLink>
                        </div>
                    </div>
                    <Player
                        autoplay
                        loops
                        src="https://assets4.lottiefiles.com/private_files/lf30_ps1145pz.json"
                        style={{ width: '500px' }}
                    >
                    </Player>
                </section>
                <section id="support">
                    <div className="advantage-elm">
                        <Player
                            autoplay
                            loop
                            src="https://cdn.lordicon.com/vxsnbfop.json"
                            style={{ width: '300px' }}
                        >
                        </Player>
                        <div className="title">
                            <h3>
                                Enregistrez vos <span>logements</span> et vos <span>locataires</span>
                            </h3>
                            <p>
                                Enregistrez vos logements et vos locataires et oublier les dossier spécifiques. Tous se trouve au même endroit pour une gestion optimiser et intuitive, sans difficultées.
                            </p>
                        </div>
                    </div>
                    <div className="advantage-elm">
                        <Player
                            autoplay
                            loop
                            src="https://cdn.lordicon.com/ckatldkn.json"
                            style={{ width: '300px' }}
                        >
                        </Player>
                        <div className="title">
                            <h3>
                                Générer vos <span>contrats</span> en un cliques
                            </h3>
                            <p>
                                Créez vos contrats en moins de 2 minutes est envoyer les en un clics pour vous faire gagner du temps à vous et à vos locataires.
                            </p>
                        </div>
                    </div>
                    <div className="advantage-elm">
                        <Player
                            autoplay
                            loop
                            src="https://cdn.lordicon.com/oegrrprk.json"
                            style={{ width: '300px' }}
                        >
                        </Player>
                        <div className="title">
                            <h3>
                                <span>Analyser</span> vos location au fils du temps
                            </h3>
                            <p>
                                Analyser vos locations au fils des mois pour voir l'evolution de vos locations et mettre au points vos périodes d'activité pour toucher vos locataire et de nouveaux prospect.
                            </p>
                        </div>
                    </div>
                    <div className="advantage-elm">
                        <Player
                            autoplay
                            loop
                            src="https://cdn.lordicon.com/iepbfivp.json"
                            style={{ width: '300px' }}
                        >
                        </Player>
                        <div className="title">
                            <h3>
                                Envoyer vos <span>documents</span> en un éclair
                            </h3>
                            <p>
                                Envoyez vos contrats, inventaire, road map et bien plus encore depuis l'application a vos locataires, sans passer par une boite mail externe.
                            </p>
                        </div>
                    </div>
                    <div className="advantage-elm">
                        <Player
                            autoplay
                            loop
                            src="https://cdn.lordicon.com/qmcsqnle.json"
                            style={{ width: '300px' }}
                        >
                        </Player>
                        <div className="title">
                            <h3>
                                Traitez vos <span>paiments</span> sans complications
                            </h3>
                            <p>
                                Occupez vous des loyers de vos location directement depuis l'application. Retards, relances et paiements ne serons jamais aussi simple à faire.
                            </p>
                        </div>
                    </div>
                    <div className="advantage-elm">
                        <Player
                            autoplay
                            loop
                            src="https://cdn.lordicon.com/mgmiqlge.json"
                            style={{ width: '300px' }}
                        >
                        </Player>
                        <div className="title">
                            <h3>
                                Gérez vos <span>calendrier</span> de location
                            </h3>
                            <p>
                                Voyez rapidement vos périodes de réservations et de locations en un clin d'oeil. Bloquer ou libérez des dates en deux cliques de souris
                            </p>
                        </div>
                    </div>
                </section>
                <section id='CTA'>
                    <h3>Prêt à <span>booster</span> vos location et à gagner du temps ?</h3>
                    <a className='button' rel='noreferrer' href="http://app.resaloc-france.com/signup">Commencez gratuitement</a>
                </section>
            </main>
        )
    }
}

export default Features