import React from 'react'
import { NavLink } from 'react-router-dom'
import Logo from '../../assets/images/logo.svg'
import './Header.css'

class Header extends React.Component {
    render() {
        return (
            <header>
                <div>
                    <NavLink to="/"><img src={Logo} alt="logo de resaloc, application web de gestion de location" /></NavLink>
                    <nav>
                        <NavLink to="/features">Fonctionnalitées</NavLink>
                        <NavLink to="/pricing">Tarifs</NavLink>
                        <a href="https://app.resaloc-france.com/login">Se connecter</a>
                    </nav>
                </div>
            </header>
        )
    }
}

export default Header